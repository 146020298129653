import { reactive } from 'vue'

export const store = reactive({
    itemsList: [
        'Acqua pura direttamente dal tuo rubinetto, liscia o frizzante',
        'Rimuovi il costo, il peso e la plastica delle casse d\'acqua',
        'Senza lavori di muratura',
        'Richiesta gratis e senza impegno'
    ],
    buttonsList: {
        'children': ['Si', 'No'],
        'familyMembers': ['1 o 2 persone', 'Tra 3 e 5', 'Più di 5'],
        'bottledWater': ['Si, naturale', 'Si, naturale e/o frizzante', 'No'],
        'citizenship': ['Si', 'No']
    },
    regions: {
        'Abruzzo': [
            { provincia: 'L\'Aquila', id: '6' },
            { provincia: 'Chieti', id: '23' },
            { provincia: 'Pescara', id: '65' },
            { provincia: 'Teramo', id: '92' },
        ], 
        'Basilicata': [
            { provincia: 'Matera', id: '57' },
            { provincia: 'Potenza', id: '74' },
        ], 
        'Calabria': [
            { provincia: 'Cosenza', id: '28' },
            { provincia: 'Catanzaro', id: '30' },
            { provincia: 'Crotone', id: '43' },
            { provincia: 'Reggio Calabria', id: '76' },
            { provincia: 'Vibo Valentia', id: '107' },
        ], 
        'Campania': [
            { provincia: 'Avellino', id: '9' },
            { provincia: 'Benevento', id: '14' },
            { provincia: 'Caserta', id: '22' },
            { provincia: 'Napoli', id: '58' },
            { provincia: 'Salerno', id: '83' },
        ], 
        'Emilia-Romagna': [
            { provincia: 'Bologna', id: '15' },
            { provincia: 'Forlì-Cesena', id: '32' },
            { provincia: 'Ferrara', id: '33' },
            { provincia: 'Modena', id: '55' },
            { provincia: 'Piacenza', id: '63' },
            { provincia: 'Parma', id: '70' },
            { provincia: 'Ravenna', id: '75' },
            { provincia: 'Reggio Emilia', id: '77' },
            { provincia: 'Rimini', id: '81' },            
        ], 
        'Friuli-Venezia-Giulia': [
            { provincia: 'Gorizia', id: '39' },
            { provincia: 'Pordenone', id: '68' },
            { provincia: 'Trieste', id: '97' },
            { provincia: 'Udine', id: '99' },
        ], 
        'Lazio': [
            { provincia: 'Frosinone', id: '37' },
            { provincia: 'Latina', id: '48' },
            { provincia: 'Rieti', id: '79' },
            { provincia: 'Roma', id: '80' },
            { provincia: 'Viterbo', id: '106' },
        ], 
        'Liguria': [
            { provincia: 'Genova', id: '38' },
            { provincia: 'Imperia', id: '41' },
            { provincia: 'La Spezia', id: '86' },
            { provincia: 'Savona', id: '90' },
        ], 
        'Lombardia': [
            { provincia: 'Bergamo', id: '11' },
            { provincia: 'Brescia', id: '17' },
            { provincia: 'Como', id: '26' },
            { provincia: 'Cremona', id: '27' },
            { provincia: 'Lecco', id: '44' },
            { provincia: 'Lodi', id: '47' },
            { provincia: 'Monza e Brianza', id: '50' },
            { provincia: 'Milano', id: '53' },
            { provincia: 'Mantova', id: '54' },
            { provincia: 'Pavia', id: '73' },
            { provincia: 'Sondrio', id: '85' },
            { provincia: 'Varese', id: '100' },
        ], 
        'Marche': [
            { provincia: 'Ancona', id: '3' },
            { provincia: 'Ascoli Piceno', id: '5' },
            { provincia: 'Fermo', id: '36' },
            { provincia: 'Macerata', id: '51' },
            { provincia: 'Pesaro e Urbino', id: '72' },
        ], 
        'Molise': [
            { provincia: 'Campobasso', id: '21' },
            { provincia: 'Isernia', id: '42' },
        ], 
        'Piemonte': [
            { provincia: 'Alessandria', id: '2' },
            { provincia: 'Asti', id: '8' },
            { provincia: 'Biella', id: '12' },
            { provincia: 'Cuneo', id: '25' },
            { provincia: 'Novara', id: '59' },
            { provincia: 'Torino', id: '94' },
            { provincia: 'Verbano Cusio Ossola', id: '101' },
            { provincia: 'Vercelli', id: '102' },
        ], 
        'Puglia': [
            { provincia: 'Bari', id: '10' },
            { provincia: 'Brindisi', id: '16' },
            { provincia: 'Barletta-Andria-Trani', id: '18' },
            { provincia: 'Foggia', id: '34' },
            { provincia: 'Lecce', id: '45' },
            { provincia: 'Taranto', id: '91' },
        ], 
        'Sardegna': [
            { provincia: 'Cagliari', id: '20' },
            { provincia: 'Nuoro', id: '60' },
            { provincia: 'Oristano', id: '61' },
            { provincia: 'Sassari', id: '88' },
            { provincia: 'Sud Sardegna', id: '89' },
        ], 
        'Sicilia': [
            { provincia: 'Agrigento', id: '1' },
            { provincia: 'Caltanissetta', id: '24' },
            { provincia: 'Catania', id: '29' },
            { provincia: 'Enna', id: '31' },
            { provincia: 'Messina', id: '52' },
            { provincia: 'Palermo', id: '62' },
            { provincia: 'Ragusa', id: '78' },
            { provincia: 'Siracusa', id: '87' },
            { provincia: 'Trapani', id: '95' },
        ], 
        'Toscana': [
            { provincia: 'Arezzo', id: '7' },
            { provincia: 'Firenze', id: '35' },
            { provincia: 'Grosseto', id: '40' },
            { provincia: 'Livorno', id: '46' },
            { provincia: 'Lucca', id: '49' },
            { provincia: 'Massa e Carrara', id: '56' },
            { provincia: 'Pisa', id: '67' },
            { provincia: 'Prato', id: '69' },
            { provincia: 'Pistoia', id: '71' },
            { provincia: 'Siena', id: '84' },
        ], 
        'Trentino-Alto-Adige': [
            { provincia: 'Bolzano', id: '19' },
            { provincia: 'Trento', id: '93' },
        ], 
        'Umbria': [
            { provincia: 'Perugia', id: '66' },
            { provincia: 'Terni', id: '96' },
        ], 
        'Valle-d\'Aosta': [
            { provincia: 'Aosta', id: '4' },
        ], 
        'Veneto': [
            { provincia: 'Belluno', id: '13' },
            { provincia: 'Padova', id: '64' },
            { provincia: 'Rovigo', id: '82' },
            { provincia: 'Treviso', id: '98' },
            { provincia: 'Venezia', id: '103' },
            { provincia: 'Vicenza', id: '104' },
            { provincia: 'Verona', id: '105' },
        ]
    }
});