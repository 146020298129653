<script lang="ts">

export default {
    name: "Header"
}

</script>

<template>
    <header 
        class="fixed top-0 w-full left-0 z-10 bg-white" 
        :class="{ 'customShadow' : $route.path === '/', 'p-5' : $route.path === '/', 'p-2' : $route.path !== '/' }">
        <nav class="flex items-center">
            <img src="../assets/Naturalis_Logo_orizzontale.png" alt="logo" class="w-44">
        </nav>
    </header>
</template>

<style lang="scss" scoped>

.customShadow {
    box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.75);
    -webkit-box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.75);
}
</style>