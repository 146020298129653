<script lang="ts">
import { store } from "../data/store";
import BotAvatar from "../components/BotAvatar.vue";
import Checks from "../shared/checks";
import LeadModel from "../shared/leadModel";
import EventTracking from "../shared/eventsTracking";
import Utils from "../shared/utils";
import LeadService from "../shared/leadService";

interface Region {
    provincia: string;
    id: string;
}

interface Regions {
    [key: string]: Region[];
}

export default {
    name: "HomeView",
    components: {
        BotAvatar,
    },
    data() {
        const lead: LeadModel = new LeadModel();
        const utils: Utils = new Utils();

        lead.name_landing = process.env.VUE_APP_NAME_LANDING;
        lead.uuid_landing = process.env.VUE_APP_UUID_LANDING;
        lead.uuid_user = utils.getUUID();

        const eventTracking: EventTracking = new EventTracking(
            process.env.VUE_APP_NAME_LANDING,
            process.env.VUE_APP_UUID_CUSTOMER,
            process.env.VUE_APP_UUID_LANDING
        );

        return {
            store: {
                regions: store.regions as Regions,
                itemsList: store.itemsList,
                buttonsList: store.buttonsList,
            },
            loadingVisible: "loading-0",
            currentStep: 0,
            provinces: [] as Region[],
            errors: [] as string[],
            phoneErrors: [] as string[],
            queryLocation: false,
            lead,
            eventTracking,
            uuidCustomer: process.env.VUE_APP_UUID_CUSTOMER,
            uuidLanding: process.env.VUE_APP_UUID_LANDING,
            currentTimes: [] as string[],
            invalidPhone: false,
            // User Answers:
            selectedRegion: "",
            selectedProvince: { provincia: "", id: "" },
            selectedChild: "",
            selectedFamMembers: "",
            selectedWater: "",
            selectedCitizen: "",
            fullname: "",
            firstname: "",
            lastname: "",
            invalidPhoneNumber: "",
        };
    },
    methods: {
        setTime() {
            const options: Intl.DateTimeFormatOptions = {
                hour: "numeric",
                minute: "numeric",
            };
            this.now = new Date().toLocaleString("it-IT", options);
            this.currentTimes.push(this.now);
            // console.log(this.currentTimes);
        },
        getCurrentTime(i) {
            return this.currentTimes[i];
        },
        scrollChatDown() {
            this.$refs.chatbot.scrollIntoView({
                behavior: "smooth",
                block: "end",
            });
        },
        changeFirstStep(region: string, province: string | undefined) {
            // Capitalize first letters
            region = region
                .toLowerCase()
                .replace(/(?:^|-)([a-z])/g, (match) => match.toUpperCase());

            // Check if Region exists in Store
            if (this.store.regions.hasOwnProperty(region)) {
                this.selectedRegion = region;
                this.currentStep = 0;
            }

            if (province && province !== "" && province !== undefined) {
                // Capitalize first letters & remove dashes
                let formattedProvince =
                    province.charAt(0).toUpperCase() +
                    province
                        .slice(1)
                        .toLowerCase()
                        .replace(
                            /-([a-z])/g,
                            (match) => " " + match.charAt(1).toUpperCase()
                        );
                province = formattedProvince;

                // Check if Province exists in Store & set selectedProvince
                const regionArray = this.store.regions[this.selectedRegion];
                for (let item in regionArray) {
                    if (regionArray[item].provincia === province) {
                        this.selectedProvince = regionArray[item];
                        this.sendCustomEvent("naturalis_chat_city", province);
                        this.queryLocation = true;
                        break;
                    }
                }
            }
            this.loadNextStep("loading-0", "message-0");
        },
        loadNextStep(load: string, msg: string) {
            const loadEl = this.$refs[load] as HTMLElement;
            const msgEl = this.$refs[msg] as HTMLElement;

            if (loadEl && msgEl) {
                // Show Step Loading
                this.setTime();
                loadEl.style.display = "block";
                msgEl.style.display = "none";
                this.scrollChatDown();

                setTimeout(() => {
                    // Show Step Message
                    loadEl.style.display = "none";
                    msgEl.style.display = "block";
                    this.scrollChatDown();

                    if (load === "loading-0" && !this.queryLocation) {
                        this.currentStep = 1;
                        this.loadNextStep("loading-1", "message-1");
                    } else if (
                        load === "loading-0" &&
                        this.queryLocation === true
                    ) {
                        this.currentStep = 2;
                        this.setTime();
                        this.loadNextStep("loading-2", "message-2");
                    }
                }, 2000);
            }
        },
        getIntroText() {
            let text;
            if (this.queryLocation === true) {
                text =
                    " e vivi in provincia di " +
                    this.selectedProvince.provincia;
            } else if (this.selectedRegion !== "") {
                text = " e vivi in " + this.selectedRegion;
            } else {
                text = "";
            }
            return text;
        },
        changeStep() {
            this.currentStep++;
            this.loadNextStep(
                `loading-${this.currentStep}`,
                `message-${this.currentStep}`
            );
        },
        // Add User's Answer & Call Next Step
        clickAnswer(answer: string) {
            // Update User's Answer & Send GTM Event
            switch (this.currentStep) {
                case 2:
                    this.selectedChild = answer;
                    this.sendCustomEvent("naturalis_chat_children", answer);
                    break;
                case 3:
                    this.selectedFamMembers = answer;
                    this.sendCustomEvent(
                        "naturalis_chat_familymembers",
                        answer
                    );
                    break;
                case 4:
                    this.selectedWater = answer;
                    this.sendCustomEvent("naturalis_chat_bottledwater", answer);
                    break;
                case 5:
                    this.selectedCitizen = answer;
                    this.sendCustomEvent(
                        "naturalis_chat_itacitizenship",
                        answer
                    );
                    break;
            }

            // Show User's Chatbot Answer & Call Next Step
            const variable = `answer-${this.currentStep}` as string;
            const userAnswer = this.$refs[variable] as HTMLSelectElement;
            if (userAnswer) userAnswer.style.display = "flex";
            this.changeStep();

            // If Last Step, Show Form
            if (this.currentStep === 6) {
                setTimeout(() => {
                    this.changeStep();
                }, 3100);
            }
        },
        // Clean Answers
        restartChat() {
            this.currentStep = 1;
            this.selectedChild = "";
            this.selectedFamMembers = "";
            this.selectedWater = "";
            this.selectedCitizen = "";
            this.fullname = "";
            this.firstname = "";
            this.lastname = "";
            this.lead.mobile_phone = "";
            for (let i = 1; i < 6; i++) {
                let userAnswer = this.$refs[`answer-${i}`] as HTMLSelectElement;
                if (userAnswer && userAnswer.style.display === "flex")
                    userAnswer.style.display = "none";
            }
            this.currentTimes = this.currentTimes.slice(0, 2);
        },
        cleanProvince() {
            this.selectedProvince = { provincia: "", id: "" };
        },
        async sendCustomEvent(eventName: string, eventValue: string) {
            // console.log(eventName, eventValue);

            await this.eventTracking.trackEvent(
                eventName,
                "track",
                eventValue,
                true,
                false,
                null,
                null
            );
        },
        async validateForm() {
            // console.log("validating form");

            // Validate Data & Show Errors
            const checks = new Checks();
            let success = false;
            this.errors = [];

            if (!this.fullname || !this.lead.mobile_phone) {
                this.errors.push("Compila tutti i campi");
            }

            if (
                this.fullname === "" ||
                this.fullname.trim().split(" ").length < 2 ||
                !checks.isValidLength(this.fullname) ||
                !checks.hasValidWordCount(this.fullname)
            ) {
                this.errors.push("Inserisci nome e cognome");
            }

            this.firstname = this.fullname.trim().split(" ")[0];
            this.lastname = this.fullname.trim().split(this.firstname + " ")[1];

            if (
                !this.firstname ||
                this.firstname === "" ||
                !checks.isValidLength(this.firstname)
            ) {
                this.errors.push("Inserisci un nome");
            }

            if (!checks.checkPhone(this.lead.mobile_phone)) {
                this.errors.push("Inserisci un numero di telefono valido");
            }

            // console.log('fullname', this.fullname + ' ' + this.lead.mobile_phone);
            console.log(this.errors);
            // console.log(this.errors.length);

            if (!this.errors.length) {
                // Form is valid
                success = true;
                // console.log("Form is valid");

                // Hide Form Send Button
                const sendBtn = this.$refs["sendBtn"] as HTMLButtonElement;
                if (sendBtn) sendBtn.style.display = "none";

                // Create New Lead
                this.sendCustomEvent("naturalis_chat_form", null);

                const leadService = new LeadService(
                    this.uuidCustomer,
                    this.uuidLanding
                );
                this.lead.first_name = this.firstname;
                this.lead.last_name = this.lastname;
                this.lead.regione = this.selectedRegion;
                this.lead.provincia = this.selectedProvince.provincia;
                this.lead.id_provincia = this.selectedProvince.id;
                this.lead.compri_acqua = this.selectedWater;
                this.lead.bambini_in_famiglia = this.selectedChild;
                this.lead.n_componenti = this.selectedFamMembers;

                if (this.selectedCitizen === "Si") {
                    this.lead.paese_di_nascita = "italia";
                } else {
                    this.lead.paese_di_nascita = "ESTERO";
                    this.lead.uuid_landing =
                        process.env.VUE_APP_UUID_LANDING_ESTERO;
                    this.lead.uuid_customer =
                        process.env.VUE_APP_UUID_CUSTOMER_ESTERO;
                }

                console.log("Lead --> " + JSON.stringify(this.lead));
                const result = await leadService.insertLead(this.lead);

                // Good Lead
                if (result === 200)
                    this.sendCustomEvent("naturalis_chat_good_lead", null);

                // Invalid Phone & Next Step
                if (result === 403) {
                    this.invalidPhone = true;
                    this.changeStep();
                } else {
                    this.setTime();
                    this.currentStep += 2;
                    this.loadNextStep(
                        `loading-${this.currentStep}`,
                        `message-${this.currentStep}`
                    );
                }

                if (result !== 403) {
                    // Change Route
                    setTimeout(() => {
                        this.$router.push({ name: "thank-you" });
                    }, 5000);
                }
            }

            return success;
        },
        async sendWithInvalidPhone() {
            const checks = new Checks();
            this.phoneErrors = [];

            if (!checks.checkPhone(this.invalidPhoneNumber)) {
                this.phoneErrors.push("Inserisci un numero di telefono valido");
            }

            if (!this.phoneErrors.length) {
                // New Lead
                const leadService = new LeadService(
                    this.uuidCustomer,
                    this.uuidLanding
                );

                this.lead.mobile_phone = this.invalidPhoneNumber;

                if (this.lead.mobile_phone === this.invalidPhoneNumber) {
                    this.lead.force_save = true;
                }

                console.log("Lead --> " + JSON.stringify(this.lead));
                const result = await leadService.insertLead(this.lead);

                // Good Lead
                if (result === 200)
                    this.sendCustomEvent("naturalis_chat_good_lead", null);

                if (result === 403) {
                    this.phoneErrors.push(
                        "Il numero inserito non è valido. Riprova."
                    );
                } else {
                    this.changeStep();
                    setTimeout(() => {
                        this.$router.push({ name: "thank-you" });
                    }, 5000);
                }
                return true;
            }
            return false;
        },
    },
    watch: {
        // Manage Region Field Changes
        selectedRegion() {
            if (this.selectedRegion !== "" && !this.queryLocation) {
                this.provinces = this.store.regions[this.selectedRegion] ?? [];
            } else {
                this.provinces = [];
            }
            if (this.selectedProvince.provincia !== "" && !this.queryLocation) {
                this.restartChat();
                this.cleanProvince();
            }
        },
        // Manage Province Field Changes
        selectedProvince() {
            if (this.currentStep > 1) this.restartChat();
            if (
                this.selectedProvince.provincia !== "" &&
                this.selectedProvince.id !== "" &&
                !this.queryLocation &&
                this.store.regions[this.selectedRegion].some(
                    (province) =>
                        province.id === this.selectedProvince.id &&
                        province.provincia === this.selectedProvince.provincia
                )
            ) {
                this.sendCustomEvent(
                    "naturalis_chat_city",
                    this.selectedProvince.provincia
                );
                const userAnswer = this.$refs["answer-1"] as HTMLSelectElement;
                if (userAnswer) userAnswer.style.display = "flex";
                this.currentStep = 2;
                this.loadNextStep("loading-2", "message-2");
            }
        },
    },
    async beforeMount() {
        await this.eventTracking.trackEvent(
            "naturalis_chat_view",
            "track",
            null,
            true,
            false,
            null,
            null
        );
    },
    mounted() {
        const { regione, citta } = this.$route.query;
        if (
            regione &&
            regione !== "" &&
            regione !== undefined &&
            typeof regione === "string" &&
            (citta === undefined || typeof citta === "string")
        ) {
            this.changeFirstStep(regione, citta);
        } else {
            // Load Step 0
            this.loadNextStep("loading-0", "message-0");
        }
    },
};
</script>

<template>
    <main class="pt-28 flex justify-center">
        <section
            ref="chatbot"
            id="chatbot"
            class="w-full max-w-[600px] mb-4 p-8"
        >
            <!-- Step 0 - Intro -->
            <div class="step-container flex items-end gap-2 w-[80%]">
                <BotAvatar :timestamp="getCurrentTime(0)" />

                <!-- Bot Loading -->
                <div ref="loading-0" class="rounded-xl px-10 py-4 bg-white">
                    <div class="dot-pulse"></div>
                </div>

                <!-- Bot Message -->
                <div
                    ref="message-0"
                    class="bot-message font-normal rounded-xl p-4 bg-white"
                >
                    <p>
                        Hai più di 30 anni{{ getIntroText() }}?
                        <strong
                            >Richiedi subito il tuo purificatore d'acqua
                            GRATUITO!
                        </strong>
                        <br />
                        (dovrai occuparti solo della manutenzione annuale)
                    </p>
                    <ol class="flex flex-col mt-4">
                        <li
                            v-for="item in store.itemsList"
                            class="flex items-start"
                        >
                            <span class="leading-6 mr-1">✅</span>
                            <span class="leading-6">{{ item }}</span>
                        </li>
                    </ol>
                </div>
            </div>

            <!-- Step 1 - Select Region/City -->
            <div
                v-show="currentStep > 0 && !queryLocation"
                class="step-container flex items-end gap-2 w-[80%] my-4"
            >
                <BotAvatar :timestamp="getCurrentTime(1)" />

                <!-- Bot Loading -->
                <div ref="loading-1" class="rounded-xl px-10 py-4 bg-white">
                    <div class="dot-pulse"></div>
                </div>

                <!-- Bot Message -->
                <div
                    ref="message-1"
                    class="bot-message font-normal rounded-xl p-4 bg-white flex flex-col gap-4"
                >
                    <p>
                        Seleziona la tua{{
                            selectedRegion ? " provincia " : " regione "
                        }}per richiedere il purificatore gratuito
                    </p>

                    <!-- Select Region -->
                    <select
                        name="regione"
                        id="selectRegione"
                        v-model="selectedRegion"
                        class="mb-4 mt-6 border-2 p-2 border-[#4A4A4A] rounded w-[80%]"
                    >
                        <option value="">Seleziona Regione</option>
                        <template v-for="(region, i) in store.regions">
                            <option :value="i">{{ i }}</option>
                        </template>
                    </select>

                    <!-- Select City -->
                    <select
                        v-if="provinces && provinces.length > 0"
                        v-model="selectedProvince"
                        name="province"
                        id="selectProvince"
                        class="mb-4 border-2 p-2 border-[#4A4A4A] rounded w-[80%]"
                    >
                        <option :value="{ provincia: '', id: '' }">
                            Seleziona provincia
                        </option>
                        <template v-for="province in provinces">
                            <option
                                :value="{
                                    provincia: province.provincia,
                                    id: province.id,
                                }"
                            >
                                {{ province.provincia }}
                            </option>
                        </template>
                    </select>
                </div>
            </div>

            <!-- User's Step 1 Answer -->
            <div
                ref="answer-1"
                class="flex justify-end mt-4 mb-8"
                :class="{ hidden: currentStep <= 1 || queryLocation }"
            >
                <div
                    class="w-[40%] answer bg-[#FFD328] p-2 rounded-xl text-end"
                >
                    <span>{{ selectedProvince.provincia }}</span>
                </div>
            </div>

            <!-- Step 2 - Select Children -->
            <div
                v-show="currentStep > 1"
                class="step-container flex items-end gap-2 w-[80%] my-4"
            >
                <BotAvatar :timestamp="getCurrentTime(2)" />

                <!-- Bot Loading -->
                <div ref="loading-2" class="rounded-xl px-10 py-4 bg-white">
                    <div class="dot-pulse"></div>
                </div>

                <!-- Bot Message -->
                <div
                    ref="message-2"
                    class="bot-message font-normal rounded-xl p-4 bg-white flex flex-col gap-4 w-full"
                >
                    <p>Nella tua famiglia ci sono bambini?</p>

                    <div v-if="selectedChild == ''" class="mt-5">
                        <template v-for="item in store.buttonsList.children">
                            <button class="btn" @click="clickAnswer(item)">
                                <span>{{ item }}</span>
                            </button>
                        </template>
                    </div>
                </div>
            </div>

            <!-- User's Step 2 Answer -->
            <div ref="answer-2" class="hidden flex justify-end mt-4 mb-8">
                <div
                    class="w-[40%] answer bg-[#FFD328] p-2 rounded-xl text-end"
                >
                    <span>{{ selectedChild }}</span>
                </div>
            </div>

            <!-- Step 3 - Select Family Members -->
            <div
                v-show="currentStep > 2"
                class="step-container flex items-end gap-2 w-[80%] my-4"
            >
                <BotAvatar :timestamp="getCurrentTime(3)" />

                <!-- Bot Loading -->
                <div ref="loading-3" class="rounded-xl px-10 py-4 bg-white">
                    <div class="dot-pulse"></div>
                </div>

                <!-- Bot Message -->
                <div
                    ref="message-3"
                    class="bot-message font-normal rounded-xl p-4 bg-white flex flex-col gap-4 w-full"
                >
                    <p>Quanti siete in famiglia?</p>

                    <div v-if="selectedFamMembers == ''" class="mt-5">
                        <template
                            v-for="item in store.buttonsList.familyMembers"
                        >
                            <button class="btn" @click="clickAnswer(item)">
                                <span>{{ item }}</span>
                            </button>
                        </template>
                    </div>
                </div>
            </div>

            <!-- User's Step 3 Answer -->
            <div ref="answer-3" class="hidden flex justify-end mt-4 mb-8">
                <div
                    class="w-[40%] answer bg-[#FFD328] p-2 rounded-xl text-end"
                >
                    <span>{{ selectedFamMembers }}</span>
                </div>
            </div>

            <!-- Step 4 - Bottled Water -->
            <div
                v-show="currentStep > 3"
                class="step-container flex items-end gap-2 w-[80%] my-4"
            >
                <BotAvatar :timestamp="getCurrentTime(4)" />

                <!-- Bot Loading -->
                <div ref="loading-4" class="rounded-xl px-10 py-4 bg-white">
                    <div class="dot-pulse"></div>
                </div>

                <!-- Bot Message -->
                <div
                    ref="message-4"
                    class="bot-message font-normal rounded-xl p-4 bg-white flex flex-col gap-4 w-full"
                >
                    <p>Compri acqua in bottiglia?</p>

                    <div v-if="selectedWater == ''" class="mt-5">
                        <template
                            v-for="item in store.buttonsList.bottledWater"
                        >
                            <button class="btn" @click="clickAnswer(item)">
                                <span>{{ item }}</span>
                            </button>
                        </template>
                    </div>
                </div>
            </div>

            <!-- User's Step 4 Answer -->
            <div ref="answer-4" class="hidden flex justify-end mt-4 mb-8">
                <div
                    class="w-[40%] answer bg-[#FFD328] p-2 rounded-xl text-end"
                >
                    <span>{{ selectedWater }}</span>
                </div>
            </div>

            <!-- Step 5 - Italian Citizenship -->
            <div
                v-show="currentStep > 4"
                class="step-container flex items-end gap-2 w-[80%] my-4"
            >
                <BotAvatar :timestamp="getCurrentTime(5)" />

                <!-- Bot Loading -->
                <div ref="loading-5" class="rounded-xl px-10 py-4 bg-white">
                    <div class="dot-pulse"></div>
                </div>

                <!-- Bot Message -->
                <div
                    ref="message-5"
                    class="bot-message font-normal rounded-xl p-4 bg-white flex flex-col gap-4 w-full"
                >
                    <p>Hai la cittadinanza italiana?</p>

                    <div v-if="selectedCitizen == ''" class="mt-5">
                        <template v-for="item in store.buttonsList.citizenship">
                            <button class="btn" @click="clickAnswer(item)">
                                <span>{{ item }}</span>
                            </button>
                        </template>
                    </div>
                </div>
            </div>

            <!-- User's Step 5 Answer -->
            <div ref="answer-5" class="hidden flex justify-end mt-4 mb-8">
                <div
                    class="w-[40%] answer bg-[#FFD328] p-2 rounded-xl text-end"
                >
                    <span>{{ selectedCitizen }}</span>
                </div>
            </div>

            <!-- Step 6 - Complete Form Message -->
            <div
                v-show="currentStep > 5"
                class="step-container flex items-end gap-2 w-[80%] my-4"
            >
                <BotAvatar :timestamp="getCurrentTime(6)" />

                <!-- Bot Loading -->
                <div ref="loading-6" class="rounded-xl px-10 py-4 bg-white">
                    <div class="dot-pulse"></div>
                </div>

                <!-- Bot Message -->
                <div
                    ref="message-6"
                    class="bot-message font-normal rounded-xl p-4 bg-white flex flex-col gap-4 w-full"
                >
                    <p>
                        <strong
                            >Benissimo! Compila i dati per richiedere il
                            purificatore d'acqua gratis + installazione +
                            manutenzione ⬇️</strong
                        >
                    </p>
                </div>
            </div>

            <!-- Step 7 - Form -->
            <div
                v-show="currentStep > 6"
                class="step-container flex items-end gap-2 w-[80%] my-4"
            >
                <BotAvatar :timestamp="getCurrentTime(7)" />

                <!-- Bot Loading -->
                <div ref="loading-7" class="rounded-xl px-10 py-4 bg-white">
                    <div class="dot-pulse"></div>
                </div>

                <!-- Bot Message -->
                <div
                    ref="message-7"
                    class="bot-message font-normal rounded-xl p-4 bg-white flex flex-col gap-4 w-full"
                >
                    <form>
                        <!-- Full Name -->
                        <div>
                            <label
                                for="fullname"
                                class="font-bold text-center w-full block"
                                >NOME e COGNOME</label
                            >
                            <input
                                type="text"
                                id="fullname"
                                name="fullname"
                                v-model="fullname"
                                placeholder="Scrivi qui..."
                                required
                                class="mt-2 mb-8 border-2 p-2 border-[#4A4A4A] rounded w-full"
                                :class="{
                                    'border-red-500': errors.includes(
                                        'Inserisci nome e cognome'
                                    ),
                                }"
                            />
                        </div>

                        <!-- Phone -->
                        <div>
                            <label
                                for="phone"
                                class="font-bold text-center w-full block"
                                >Telefono</label
                            >
                            <input
                                type="tel"
                                id="phone"
                                name="phone"
                                v-model="lead.mobile_phone"
                                placeholder="Scrivi qui..."
                                required
                                class="mt-2 mb-8 border-2 p-2 border-[#4A4A4A] rounded w-full"
                                :class="{
                                    'border-red-500': errors.includes(
                                        'Inserisci un numero di telefono valido'
                                    ),
                                }"
                            />
                        </div>

                        <!-- Error message -->
                        <p
                            v-if="errors.length > 0"
                            class="text-red-500 rounded text-center text-sm mb-6 mt-[-15px]"
                        >
                            ⚠️ {{ errors[0] }}
                        </p>

                        <!-- Privacy Policy -->
                        <p class="text-center mb-4">
                            <span class="text-[10px] text-[#8f8f8f]"
                                >* acconsenti che i tuoi dati personali siano
                                comunicati a terzi esclusivamente per le
                                finalità riportate in
                            </span>
                            <a
                                class="text-[10px] text-[#8f8f8f] underline"
                                href="https://everset.it/privacy-policy/it/privacy-policy.pdf"
                                target="_blank"
                                >Privacy Policy</a
                            >
                        </p>

                        <!-- Send Button -->
                        <button
                            ref="sendBtn"
                            class="sendBtn text-lg arial"
                            @click="validateForm()"
                            type="button"
                        >
                            RICHIEDI INFORMAZIONI
                        </button>
                    </form>
                </div>
            </div>

            <!-- Step 8bis - Invalid Phone Number -->
            <div
                v-show="currentStep > 7 && invalidPhone === true"
                class="step-container flex items-end gap-2 w-[80%] my-4"
            >
                <BotAvatar :timestamp="getCurrentTime(8)" />

                <!-- Bot Loading -->
                <div ref="loading-8" class="rounded-xl px-10 py-4 bg-white">
                    <div class="dot-pulse"></div>
                </div>

                <!-- Bot Message -->
                <div
                    ref="message-8"
                    class="bot-message font-normal rounded-xl p-4 bg-white flex flex-col gap-4 w-full"
                >
                    <p>
                        Ops! Sembra che il numero di telefono che hai inserito
                        non sia corretto, inserisci un numero valido per
                        continuare:
                    </p>

                    <form>
                        <!-- Phone -->
                        <div>
                            <label
                                for="phone"
                                class="font-bold text-center w-full block"
                                >Telefono</label
                            >
                            <input
                                type="tel"
                                id="phone"
                                name="phone"
                                v-model="invalidPhoneNumber"
                                placeholder="Scrivi qui..."
                                required
                                class="mt-2 mb-8 border-2 p-2 border-[#4A4A4A] rounded w-full"
                                :class="{
                                    'border-red-500': errors.includes(
                                        'Inserisci un numero di telefono valido'
                                    ),
                                }"
                            />
                        </div>

                        <!-- Error message -->
                        <p
                            v-if="phoneErrors.length > 0"
                            class="text-red-500 rounded text-center text-sm mb-6 mt-[-15px]"
                        >
                            ⚠️ {{ phoneErrors[0] }}
                        </p>

                        <!-- Send Button -->
                        <button
                            ref="resendBtn"
                            class="sendBtn text-lg arial"
                            @click="sendWithInvalidPhone()"
                            type="button"
                        >
                            CONTINUA
                        </button>
                    </form>
                </div>
            </div>

            <!-- Step 8 - Success Message -->
            <div
                v-show="currentStep > 8"
                class="step-container flex items-end gap-2 w-[80%] my-4"
            >
                <BotAvatar :timestamp="getCurrentTime(9)" />

                <!-- Bot Loading -->
                <div ref="loading-9" class="rounded-xl px-10 py-4 bg-white">
                    <div class="dot-pulse"></div>
                </div>

                <!-- Bot Message -->
                <div
                    ref="message-9"
                    class="bot-message font-normal rounded-xl p-4 bg-white flex flex-col gap-4 w-full"
                >
                    <p>
                        Ottimo, verrai contattato da un nostro consulente entro
                        24h al numero che ci hai fornito, tieni il telefono
                        vicino!
                    </p>
                </div>
            </div>
        </section>
    </main>
</template>

<style scoped>
main {
    background: linear-gradient(0deg, #5792b1e2, #5792b1da),
        url(../assets/main-img.jpg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
    min-height: 100dvh;
    overflow-y: scroll;
}

.dot-pulse {
    position: relative;
    left: -9999px;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: #000000;
    color: #000000;
    box-shadow: 9999px 0 0 -5px;
    animation: dot-pulse 1.5s infinite linear;
    animation-delay: 0.25s;
}

.dot-pulse::before,
.dot-pulse::after {
    content: "";
    display: inline-block;
    position: absolute;
    top: 0;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: #000000;
    color: #000000;
}

.dot-pulse::before {
    box-shadow: 9984px 0 0 -5px;
    animation: dot-pulse-before 1.5s infinite linear;
    animation-delay: 0s;
}

.dot-pulse::after {
    box-shadow: 10014px 0 0 -5px;
    animation: dot-pulse-after 1.5s infinite linear;
    animation-delay: 0.5s;
}

.btn,
.sendBtn {
    background-color: #004b80;
    width: 100%;
    color: white;
    text-align: center;
    border-radius: 0.75rem;
    padding: 0.8rem 0;
    margin: 0.3rem 0;
}

.btn:hover,
.btn:focus {
    animation: buttonScale 0.8s forwards;
}

form,
.invalid-phone {
    padding: 2.5rem 2.2rem;

    & .sendBtn {
        padding: 8px 0;
        border-radius: 0.4rem;
        font-weight: 400;
    }
}

/* Media Queries */
@media screen and (max-width: 600px) {
    #chatbot {
        max-width: 330px;
        padding: 1.6rem 10px;

        & .step-container {
            width: 100%;
        }

        & .answer {
            width: 60%;
        }

        & form,
        .invalid-phone {
            padding: 1.2rem 0.8rem;

            & .sendBtn {
                padding: 8px 20px;
                line-height: 23px;
            }
        }
    }
}

/* Animations */
@keyframes buttonScale {
    50% {
        transform: scale(0.9);
    }
}

@keyframes dot-pulse-before {
    0% {
        box-shadow: 9984px 0 0 -5px;
    }

    30% {
        box-shadow: 9984px 0 0 2px;
    }

    60%,
    100% {
        box-shadow: 9984px 0 0 -5px;
    }
}

@keyframes dot-pulse {
    0% {
        box-shadow: 9999px 0 0 -5px;
    }

    30% {
        box-shadow: 9999px 0 0 2px;
    }

    60%,
    100% {
        box-shadow: 9999px 0 0 -5px;
    }
}

@keyframes dot-pulse-after {
    0% {
        box-shadow: 10014px 0 0 -5px;
    }

    30% {
        box-shadow: 10014px 0 0 2px;
    }

    60%,
    100% {
        box-shadow: 10014px 0 0 -5px;
    }
}
</style>
