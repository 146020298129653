import ParamsModel from "./paramsModel";

class LeadModel extends ParamsModel {
    first_name: string;
    last_name: string;
    mobile_phone: string;
    regione: string;
    provincia: string;
    bambini_in_famiglia: string;
    n_componenti: string;
    compri_acqua: string;
    paese_di_nascita: string;
    is_visible: boolean = true;
    name_landing: string;
    uuid_landing: string;
    uuid_customer: string;
    id_provincia: number;
    uuid_user: string;
    fbp: string = this.utils.getCookie("_fbp");
    fbc: string = this.utils.getCookie("_fbc");
    force_save: boolean = false;

    constructor() {
        super();
    }
}

export default LeadModel;
