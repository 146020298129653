class Checks {
    /**
     *
     */
    constructor() {}

    checkPhone(phone: string) {
        var regex = /^[0-9]+$/;
        if (!regex.test(phone)) {
            return false;
        }
        if (
            phone == "" ||
            phone.length < 9 ||
            phone.length > 10 ||
            phone.indexOf("333333") !== -1 ||
            phone.indexOf("111111") !== -1 ||
            phone.indexOf("000000") !== -1 ||
            phone.indexOf("222222") !== -1 ||
            phone.indexOf("444444") !== -1 ||
            phone.indexOf("555555") !== -1 ||
            phone.indexOf("666666") !== -1 ||
            phone.indexOf("777777") !== -1 ||
            phone.indexOf("888888") !== -1 ||
            phone.indexOf("999999") !== -1
        ) {
            return false;
        }
        var prefix = [
            "320",
            "323",
            "324",
            "327",
            "328",
            "329",
            "330",
            "331",
            "333",
            "334",
            "335",
            "336",
            "337",
            "338",
            "339",
            "340",
            "341",
            "342",
            "343",
            "344",
            "345",
            "346",
            "347",
            "348",
            "349",
            "350",
            "351",
            "360",
            "362",
            "363",
            "366",
            "368",
            "370",
            "371",
            "373",
            "375",
            "379",
            "353",
            "377",
            "380",
            "383",
            "385",
            "388",
            "389",
            "390",
            "391",
            "392",
            "393",
            "397",
        ];
        var prefix_tel = phone.substring(0, 3);

        return prefix.indexOf(prefix_tel) > -1;
    }

    isValidLength = (str: string) => str.length >= 4 && str.length <= 20;
    hasValidWordCount = (str: string) => str.trim().split(" ").length <= 3;
}

export default Checks;
