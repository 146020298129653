<script lang="ts">
import { RouterLink, RouterView } from 'vue-router';
import Header from "./components/Header.vue";
import Utils from "./shared/utils";
// import Footer from "./components/Footer.vue";

export default {
  name: "App",
  components: {
    Header,
    // Footer,
  },
  created() {
    const utils = new Utils();
    utils.setUUID();
  }
};

</script>

<template>
  <Header />

  <RouterView />

  <!-- <Footer /> -->
</template>

<style scoped></style>
